import loginRequest from '@/utils/loginRequest'
import request from '@/utils/request'


// 定义全局变量 $location
const $location = 'https://xyz.macnninc.com/api/'; 


//注册
export function register(params) {
  return loginRequest({
    url: `${$location}frontend/member/register`,
    method: 'post',
    params
  })
}

//找回密码
export function retrieve(params) {
  return loginRequest({
    url: `${$location}frontend/member/forget-password`,
    method: 'post',
    params
  })
}

//测试获取验证码
export function sendTest(params) {
  return loginRequest({
    url: `${$location}frontend/test/send-email`,
    method: 'post',
    params
  })
}


//获取验证码
export function sendVerifyCode(params) {
  return loginRequest({
    url: `${$location}frontend/member/send-verify-code`,
    method: 'post',
    params
  })
}




//密码登录
export function passwordLogin(params) {
  return loginRequest({
    url: `${$location}frontend/members/login`,
    method: 'post',
    params
  })
}





//获取手机验证码
export function getTelphoneMsgCode(params) {
  return loginRequest({
    url: `${$location}frontend/sms/get-code`,
    method: 'post',
    params
  })
}

//手机短信验证码登录
export function TelphoneMsgCodeLogin(params) {
  return loginRequest({
    url: `${$location}frontend/members/login`,
    method: 'post',
    params
  })
}


//扫码登录
export function scanLogin(params) {
  return loginRequest({
    url: `${$location}frontend/members/login`,
    method: 'post',
    params
  })
}



//绑定扫码人员link
export function scanBind(params) {
  return loginRequest({
    url: `${$location}frontend/members/scan-bind`,
    method: 'post',
    params
  })
}


//保存注册信息
export function saveProfile(params) {
  return loginRequest({
    url: `${$location}frontend/members/profile`,
    method: 'post',
    params
  })
}